export const brands = ["Apple", "Samsung", "Huawei", "Xiaomi"]
export const modelPhone = {
  Apple: [
    "Iphone 12 Pro Max",
    "Iphone 12 Pro",
    "Iphone12 mini",
    "Iphone 12",
    "Iphone 11 Pro Max",
    "Iphone 11 Pro",
    "Iphone 11",
    "Iphone XS Max",
    "Iphone X",
    "Iphone XR",
    "Iphone XS",
  ],
  Samsung: [
    "Galaxy S10 plus",
    "Galaxy S10",
    "Galaxy S10e",
    "Galaxy S9 plus",
    "Galaxy S9",
    "Galaxy S8 plus",
    "Galaxy S8",
    "Galaxy S7",
    "Galaxy S6 Edge Plus",
    "Galaxy S6 Edge",
  ],
  Huawei: ["Huwei 0", "Huwei 1", "Huwei 2", "Huwei 3", "Huwei 4"],
  Xiaomi: ["Xiaomi 1", "Xiaomi 2", "Xiaomi 3", "Xiaomi 4", "Xiaomi 5"],
}

export const problems = [
  "Backcover Reparatur (Glas)",
  "Akku Reparatur",
  "Backcover komplett Reparatur (Glas & Mitlelrahmen)",
  "Kameraglas Reparatur",
  "Fehlerdiagnose",
  "Wasserschaden Diagnose",
  "Face ID - Gesichtserkennung Reparatur",
  "Ladeport Reparatur",
  "Pabzerglas Premium",
  "Silikonküller Transparent",
  "Kostenvorschlag für Ihre Versicherung",
  "Rückkamera Reparatur",
  "Lautsprecher Reparatur",
  "Frontkamera Reparatur",
  "Mikrofon Reparatur",
  "Mainboard Platinen Reparatur",
]
