import React, { useEffect } from "react"
import Layout from "../components/Layout/layout"

import SEO from "../components/seo"
import Intro from "../components/Intro"
import RelateProduct from "../components/Products/RelateProduct"
import useGatsbyStripeData from "../components/hooks/useGatsbyStripeData"
import Termin from "../components/Termin"
import Loading from "../components/Loading"

const TerminPage = ({ location }) => {
  const data = useGatsbyStripeData()
  const handleScroll = e => {
    const siteHeader = document.querySelector(".site-header")
    const linkItem = document.querySelectorAll(".header-link__item")
    const element = e.target.firstElementChild
    if (element.scrollTop > 0) {
      // siteHeader.style.boxShadow = "0 0 1px 1px #cecece"
      siteHeader.style.background = "black"
      ;[...linkItem].forEach(item => (item.style.color = "white"))
    } else {
      siteHeader.style.background = "unset"
      // ;[...linkItem].forEach(item => (item.style.color = "black"))
      // siteHeader.style.boxShadow = "unset"
    }
  }

  useEffect(() => {
    const siteHeader = document.querySelector(".site-header")
    siteHeader.style.background = "black"
  }, [])
  return (
    <Layout>
      <SEO title="Termin Buchen" />

      <Termin />
      {/* <Intro location={location} /> */}
      <div className="product-related wrap-page">
        <RelateProduct data={data} />
      </div>
    </Layout>
  )
}

export default TerminPage
